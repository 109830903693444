$ccm-color: #fff;
$ccm-background-color: #535353;

.ccm-root {
    .ccm-modal {
        &[aria-hidden='false'] {
            .ccm-modal {
                &-inner {
                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 50%;
                        left: 50%;
                        width: 200vw;
                        height: 200vh;
                        background-color: rgba(0, 0, 0, .4);
                        pointer-events: none;
                        transform: translate(-50%, -50%);
                        transition: opacity .2s linear;
                    }
                }
            }

            ~ .ccm-modal {
                &[aria-hidden='false'] {
                    .ccm-modal {
                        &-inner {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &-inner {
            box-shadow: 0 5px 20px rgba(0, 0, 0, .16);

            @media (min-width: 64.0625em) {
                border-radius: var(--border-radius, 4px);
                //overflow: hidden;
            }
        }

        &--header {
            padding: 16px 32px;
            font-size: inherit;
            font-weight: var(--font-weight-bold, 700);
            color: var(--color-font, #1e1e1e);
            background-color: var(--color-gray-light, #f2f4f7);

            @media (max-width: 767px) {
                padding: 16px;
            }

            @media (min-width: 64.0625em) {
                border-radius: var(--border-radius, 4px) var(--border-radius, 4px) 0 0;
            }

            &:empty,
            &:not(:has(*)) {
                display: none;
            }

            &:has(*) {
                + .ccm-modal--body {
                    @media (min-width: 64.0625em) {
                        border-radius: 0;
                    }
                }
            }

            .ccm-modal--title {
                font-size: inherit;
                font-weight: var(--font-weight-bold, 700);
            }
        }

        &--body {
            padding: 32px;
            border-bottom: 0;

            @media (max-width: 767px) {
                padding: 24px 16px;
            }

            @media (min-width: 64.0625em) {
                border-radius: var(--border-radius, 4px) var(--border-radius, 4px) 0 0;
            }
        }

        &--footer {
            gap: 8px;
            padding: 16px 32px !important;
            color: var(--color-font, #1e1e1e);
            background-color: var(--color-gray-light, #f2f4f7);

            @media (max-width: 767px) {
                padding: 16px !important;
            }

            @media (min-width: 64.0625em) {
                border-radius: 0 0 var(--border-radius, 4px) var(--border-radius, 4px);
            }
        }

        &.ccm--is-blocking {
            background-color: transparent;
        }

        &.ccm-widget {
            .ccm-modal {
                &--footer {
                    .ccm-link-container {
                        a {
                            color: var(--color-font, #1e1e1e);
                        }
                    }
                }
            }

            .ccm-widget {
                &--buttons {
                    gap: 8px;
                    margin-top: 4px;
                    margin-bottom: 0;

                    .button,
                    button {
                        flex: 1 0 auto;
                        margin-bottom: 0;
                        max-width: none;
                        font-size: 16px;
                        font-weight: var(--font-weight-bold, 700);
                        text-transform: none;
                        text-shadow: none;
                        white-space: nowrap;
                        color: var(--color-text-negative, #fff);
                        background-color: var(--color-primary, #007ebd);
                        border: 0;
                        border-radius: var(--border-radius, 4px);

                        @include hover-focus-visible {
                            background-color: var(--color-primary-hover, #213040);
                        }

                        &:focus-visible {
                            outline: 4px solid var(--color-primary-disabled, #b6daed);
                            outline-offset: 0;
                        }
                    }
                }
            }
        }

        &.ccm-control-panel {
            .ccm-modal {
                &--footer {
                    > .button,
                    > button {
                        flex: 1 0 auto;
                        margin-bottom: 0 !important;
                        max-width: none;
                        font-size: 16px;
                        font-weight: var(--font-weight-bold, 700);
                        text-transform: none;
                        text-shadow: none;
                        white-space: nowrap;
                        color: var(--color-text-negative, #fff);
                        background-color: var(--color-primary, #007ebd);
                        border: 0;
                        border-radius: var(--border-radius, 4px);

                        @include hover-focus-visible {
                            background-color: var(--color-primary-hover, #213040);
                        }

                        &:focus-visible {
                            outline: 4px solid var(--color-primary-disabled, #b6daed);
                            outline-offset: 0;
                        }
                    }
                }
            }

            input[type="checkbox"][class*="ccm-checkbox-type--"] {
                &:focus,
                &:active {
                    + label {
                        &::before,
                        &::after {
                            box-shadow: none;
                        }
                    }
                }

                &:focus-visible {
                    + label {
                        &::after {
                            outline: 4px solid var(--color-primary-disabled, #b6daed);
                            outline-offset: 0;
                        }
                    }
                }
            }

            button.ccm-info-button {
                &:focus-visible {
                    outline: 4px solid var(--color-primary-disabled, #b6daed);
                    outline-offset: 0;
                }
            }
        }

        &.ccm-details {
            .ccm-modal {
                &--footer {
                    > .button,
                    > button {
                        //flex: 1 0 auto;
                        margin-bottom: 0 !important;
                        max-width: none;
                        font-size: 16px;
                        font-weight: var(--font-weight-bold, 700);
                        text-transform: none;
                        text-shadow: none;
                        white-space: nowrap;
                        color: var(--color-text-negative, #fff);
                        background-color: var(--color-primary, #007ebd);
                        border: 0;
                        border-radius: var(--border-radius, 4px);

                        @include hover-focus-visible {
                            background-color: var(--color-primary-hover, #213040);
                        }

                        &:focus-visible {
                            outline: 4px solid var(--color-primary-disabled, #b6daed);
                            outline-offset: 0;
                        }
                    }
                }
            }

            input[type="checkbox"][class*="ccm-checkbox-type--"] {
                &:focus,
                &:active {
                    + label {
                        &::before,
                        &::after {
                            box-shadow: none;
                        }
                    }
                }

                &:focus-visible {
                    + label {
                        &::after {
                            outline: 4px solid var(--color-primary-disabled, #b6daed);
                            outline-offset: 0;
                        }
                    }
                }
            }
        }

        .ccm-widget--text {
            .ccm-widget--title {
                margin-bottom: 20px;
                font-size: 20px;
                font-weight: var(--font-weight-bold, 700);
                line-height: 1.25;
            }
        }
    }
}

//.ccm-root {
//    .ccm-widget--text .ccm-inner-overflow {
//        padding-bottom: 32px;
//    }
//
//    // Wichtig damit man Schalter mit Tab Steuern kann
//    input[type="checkbox"][class*="ccm-checkbox-type--"] {
//        display: block !important;
//        position:fixed !important;
//        left: 5px !important;
//        visibility: visible !important;
//    }
//}
//
//// Scrollbar einstellen
//.cover-scrollbar {
//    &::before {
//        content: '' !important;
//        display: block !important;
//        position: absolute !important;
//        top: 0 !important;
//        right: 0 !important;
//        bottom: 0 !important;
//        width: 1px !important;
//        height: 100% !important;
//        background: #fff !important;
//        z-index: 1 !important;
//    }
//}
//
//// Textbox anpassungen
//.ccm-widget--text {
//    //margin: 10px !important;
//    word-wrap: normal !important;
//    //margin-bottom: 20px !important;
//    margin-bottom: 0 !important;
//
//    .ccm-widget--title {
//        margin-bottom: 20px !important;
//        font-size: 22px !important;
//        //font-weight: normal !important;
//        font-weight: var(--font-weight-bold, 700) !important;
//    }
//}
//
//.ccm-inner-overflow {
//    overflow: auto !important;
//}
//
////.ccm-widget--buttons button, .ccm-widget--buttons .button {
////    flex-grow: 1 !important;
////    width: auto !important;
////    max-width: none !important;
////    min-width: auto !important;
////    text-shadow: none !important;
////}

/* Script-Blocker */
.ccm-plugin--script-placeholder {
    padding: 4rem 1rem;

    @include media-breakpoint-up(lg) {
        padding: 8rem 1rem;
    }

    background-color: var(--color-black, #000);
    color: var(--color-white, #fff);

    h2 {
        margin: 0 0 16px;
        font-size: 2rem;
        font-weight: bold;

        color: var(--color-white, #fff);
    }

    button {
        display: block;
        margin: 32px auto 0;
        padding: .75rem 1.5rem;

        border: none;
        cursor: pointer;

        color: var(--color-ccm-font, $ccm-color);
        background: var(--color-ccm, $ccm-background-color);

        @include hover-focus-visible {
            color: var(--color-ccm, $ccm-background-color);
            background-color: var(--color-ccm-font, $ccm-color);
        }
    }
}

.ccm-cookie-declaration {
    display: flex;
    flex-direction: column;
    gap: 32px 0;

    &--list {
        display: flex;
        flex-direction: column;
        gap: 32px 0;
    }

    &--purpose {
        display: flex;
        flex-direction: column;
        gap: 24px 0;
    }

    &--embedding {
        display: flex;
        flex-direction: column;
        gap: 1em 0;
    }

    &--embedding-assets-table {
        > :not(caption) {
            > * {
                > * {
                    padding: 8px;
                    border-bottom: 1px solid $color__font;
                }
            }
        }
    }
}
